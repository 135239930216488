import { render, staticRenderFns } from "./editGiftGoods.vue?vue&type=template&id=5afaa337&scoped=true"
import script from "./editGiftGoods.vue?vue&type=script&lang=js"
export * from "./editGiftGoods.vue?vue&type=script&lang=js"
import style0 from "./editGiftGoods.vue?vue&type=style&index=0&id=5afaa337&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5afaa337",
  null
  
)

export default component.exports